<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="24">
        <a-form-item label="Name">
          <a-input
            size="large"
            class="w-100"
            :value="dataGroup.nama"
            @change="(e) => handleChange(e.target.value, 'nama')"
          />
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  props: {
    dataGroup: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleChange(value, column) {
      const payload = { value, column }
      this.$emit('handle-change', payload)
    },
  },
}
</script>

<style>
</style>
